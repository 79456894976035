export const environment = {
  production: false,
  host: 'https://test.system-method.com',
  data: {
    contacts: [
      {
        department: 'Sales',
        phone: '+45 70203010',
        email: 'sales@system-method.com',
      },
      {
        department: 'Support',
        phone: '+45 70203810',
        email: 'support@system-method.com',
      },
      {
        department: 'Corporate',
        address: 'System & Method A/S \nHåndværkersvinget 8,\n2970 Hørsholm',
      },
    ],
    topNavigation: [
      {
        text: 'Support',
        link: '/page/support',
        special: false,
        textOver: 'support',
      },
      {
        text: 'Contact',
        link: '/contact',
        special: false,
        textOver: 'contacts',
      },
      {
        text: 'Products',
        link: '/solutions',
        special: true,
        textOver: 'see all products',
      },
    ],
    allPossibleSoftwares: [
      'BlueSeries Fax',
      'BlueSeries Hardware',
      'BlueSeries Mail',
      'BlueSeries XML',
      'BlueSeries Web Client',
      'BlueSeries Nemhandel',
      'BlueNote Hardware',
      'BlueNote Monitor',
      'BlueNote Communicator',
      'IceBreak™ Community Edition',
      'IceBreak™ Server Edition',
      'IceBreak™ Enterprise Edition',
      'IceCap Effieciency',
      'IceCap Functionality',
      'IceCap Architecture',
    ],
    allPersons: [
      {
        name: 'Martin Hecht Olsen',
        position: 'CEO',
        image: '../../../../assets/sitemule-members/martin-hecht-olsen.jpg',
        phone: '+45 40 11 77 11',
        email: 'mho@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Niels Liisberg',
        position: 'Chief Innovation Officer',
        image: '../../../../assets/sitemule-members/niels-liisberg.jpg',
        phone: '+45 31 15 88 61',
        email: 'nli@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Jens Churchill',
        position: 'CTO',
        image: '../../../../assets/sitemule-members/jens-churchill.jpg',
        phone: '+45 30 44 80 04',
        email: 'jmc@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Sandesh Shrestha',
        position: 'Principal, Frontend',
        image: '../../../../assets/sitemule-members/sandesh-shrestha.jpg',
        phone: '+45 27 50 64 78',
        email: 'san@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Kenneth Riber Andersen',
        position: 'Principal, UX UI',
        image: '../../../../assets/sitemule-members/kenneth-riber-andersen.jpg',
        phone: '+45 61 47 26 26',
        email: 'kra@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Grith Skovboelle',
        position: 'Support Lead',
        image: '../../../../assets/sitemule-members/grith-skovboelle.jpg',
        phone: '+45 51 27 44 43',
        email: 'gsk@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Bent Witte Rasmussen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/bent.jpg',
        phone: '+45 20 87 55 26',
        email: 'bwr@sitemule.com',
      },
      {
        name: 'Claus Tümmler',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 25 23 22 40',
        email: 'ctu@sitemule.com',
      },
      {
        name: 'Glenn Jarzomkowski',
        position: 'Developer',
        image: '../../../assets/sitemule-members/glenn.jpg',
        phone: '+45 26 73 45 99',
        email: 'gja@sitemule.com',
      },
      {
        name: 'Jeppe K. Bundgaard',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 50 70 35 77',
        email: 'jkb@sitemule.com',
      },
      {
        name: 'Jørn Holm',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 31 55 04 10',
        email: 'jho@sitemule.com',
      },
      {
        name: 'Claus Rytter Larsen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/claus.jpg',
        phone: '+45 20 27 19 99',
        email: 'crl@sitemule.com',
      },
      {
        name: 'Peter Donabed Nøbbe',
        position: 'Developer',
        image: '../../../assets/sitemule-members/peter.jpg',
        phone: '+45 60 24 59 52',
        email: 'pno@sitemule.com',
      },
      {
        name: 'Emil Fridthjof',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 28 73 90 34',
        email: 'erf@sitemule.com',
      },
      {
        name: 'Morten Pedersen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/morten.jpg',
        phone: '+45 28 49 15 20',
        email: 'mop@sitemule.com',
      },
    ],
    customers: [
      {
        logo: '../../../../assets/customers-logo/yx.png',
        name: 'xy',
        description: '',
        link: '/',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/textilia.png',
        name: 'dfd',
        description: '',
        link: '/',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/berry-superfos.png',
        name: 'berry-superfos',
        description: '',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/coownership.png',
        name: 'coownership',
        description: '',
        link: '/',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/santander.png',
        name: 'santander',
        description: '',
        link: '/',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/nomeco.png',
        name: 'nomeco',
        description: '',
        link: '/',
        showInSmallSize: true,
      },
      {
        logo: '../../../../assets/customers-logo/logitrans.png',
        name: 'logitrans',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/dancenter.png',
        name: 'dancenter',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/ftz.png',
        name: 'ftz',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/dhl.png',
        name: 'dhl',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/hch-gruppen.png',
        name: 'hch-gruppen',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/al-finans.png',
        name: 'al-finans',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/tjl.png',
        name: 'tjl',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/scania.png',
        name: 'scania',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/politietssprogforbund.png',
        name: 'politietssprogforbund',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/folkelarsen.png',
        name: 'folkelarsen',
        description: '',
      },
      {
        logo: '../../../../assets/customers-logo/volvo.png',
        name: 'volvo',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/midfactoring.png',
        name: 'midt-factoring',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/expedit.png',
        name: 'expedit',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/stark.png',
        name: 'stark',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/marius-pedersen.png',
        name: 'marius-pedersen',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/molslinjen.png',
        name: 'molslinjen',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/htbendix.png',
        name: 'htbendix',
        description: '',
        link: '/',
      },
      {
        logo: '../../../../assets/customers-logo/rg.png',
        name: 'rg',
        description: '',
        link: '/',
      },
    ],
    blog: [
      {
        featured: true, // Homepage Blogs
        link: '/sitemule-model-studio-blog',
        header: {
          title: 'Have you ever asked the users what they would like?',
          subtitle:
            'You probably have, but the wishes were so overwhelming that you concluded that it was out of your reach - both economically and technologically - and consequently, you have avoided speaking about it again.',
          coverImage: {
            src: '/assets/images/1696873783254.png',
            alt: 'Sitemule Model Studio',
          },
          publishDate: 'Tue 2 Jan 2024',
          author: {
            name: 'Martin Hecht Olsen, CEO',
            image: '../../../../assets/sitemule-members/martin-hecht-olsen.jpg',
            phone: '+45 40 11 77 11',
            mail: 'mho@system-method.com',
          },
          additional_posts: [
            {
              type: 'youtube',
              icon: 'play_circle_outline',
              label: 'Applications made in minutes',
              url: 'https://www.youtube.com/watch?v=e14MNfshKYI',
            },
            {
              type: 'youtube',
              icon: 'file_download_icon',
              label: 'Sitemule Model Studio',
              url: 'https://www.youtube.com/watch?v=2YJU3HRV3EY',
            },
          ],
        },
        conclusion: [
          {
            title: 'Fulfilling User Wishes',
            content:
              "Discover how Sitemule Model Studio transforms user feedback into reality, providing a user-friendly experience for accessing and presenting data. From customizable grids to dynamic charts, it's the solution users have longed for.",
          },
          {
            title: 'Streamlined ERP Access',
            content:
              "Unlock the potential of your ERP solution with Sitemule Model Studio's rapid application development. From importing existing definitions to creating new models, users can effortlessly access, organize, and publish ERP data, meeting long-standing user demands promptly.",
          },
          {
            title: 'Empowering Data Management',
            content:
              "Model Studio Unveils Versatile Data Presentation Explore Sitemule Architect's Model Studio and its innovative data presentation features. From flexible grid arrangements to detailed forms and hierarchical tree structures, users gain unprecedented control, ensuring efficient data processing and insightful visualizations.",
          },
        ],
      },
      {
        featured: true, // Homepage Blogs
        link: '/icecap-portfolio-blog',
        header: {
          title: 'Are you avoiding the black and green elephant in the room?',
          subtitle:
            "You probably don't see it because it has been there for decades, and the users have given up complaining about the lack of functionality and usability.",
          coverImage: {
            src: '/assets/images/1698749479349.gif',
            alt: 'Icecap And Portfolio',
          },
          publishDate: 'November 7, 2023',
          author: {
            name: 'Martin Hecht Olsen, CEO',
            image: '../../../../assets/sitemule-members/martin-hecht-olsen.jpg',
            phone: '+45 40 11 77 11',
            mail: 'mho@system-method.com',
          },
          additional_posts: [
            {
              type: 'youtube',
              icon: 'play_circle_outline',
              label: 'Applications made in minutes',
              url: 'https://www.youtube.com/watch?v=e14MNfshKYI',
            },
            {
              type: 'youtube',
              icon: 'file_download_icon',
              label: 'Sitemule Model Studio',
              url: 'https://www.youtube.com/watch?v=2YJU3HRV3EY',
            },
          ],
        },
        conclusion: [
          {
            title: 'Fulfilling User Wishes',
            content:
              "Discover how Sitemule Model Studio transforms user feedback into reality, providing a user-friendly experience for accessing and presenting data. From customizable grids to dynamic charts, it's the solution users have longed for.",
          },
          {
            title: 'Streamlined ERP Access',
            content:
              "Unlock the potential of your ERP solution with Sitemule Model Studio's rapid application development. From importing existing definitions to creating new models, users can effortlessly access, organize, and publish ERP data, meeting long-standing user demands promptly.",
          },
          {
            title: 'Empowering Data Management',
            content:
              "Model Studio Unveils Versatile Data Presentation Explore Sitemule Architect's Model Studio and its innovative data presentation features. From flexible grid arrangements to detailed forms and hierarchical tree structures, users gain unprecedented control, ensuring efficient data processing and insightful visualizations.",
          },
        ],
      },
    ],
  },
};
